import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '个人网址导航',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


  // {
  //   path: '/',
  //   name: '导航',
  //   component: () => import('../views/layout/index.vue'),
  //   children: [
  //     {
  //       redirect: '/home',
  //       path: '/',
  //     },
  //     {
  //       path: '/home',
  //       name: '导航首页',
  //       component: () => import('../views/home/index.vue')
  //     }
  //   ]
  // },