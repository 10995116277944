<template>
  <div id="particlesId">
    <div class="router-view">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import "particles.js";
import PcParticles from "./particles.json";
import "@/tools/drawStarts";

const route = useRoute();

onMounted(() => {
  window.particlesJS("particlesId", PcParticles);
});

watch(
  () => route?.name,
  (newData) => {
    document.title = newData || "个人网址导航";
  },
  { immediate: true }
);
</script>

<style lang="less">
@import "@/assets/css/index.less";

#particlesId {
  min-height: 100vh;

  .particles-js-canvas-el {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.3;
  }

//   .router-view {
//     opacity: 0.95;
//   }
}
</style>
